import React from "react";
import styled from "styled-components";

const StyledVideo = styled.div`
  background-color: white;
  position: relative;
  border-radius: 16px;
  margin: 20px;
  iframe {
    position: relative;
    border-radius: 16px;
    z-index: 2;
  }
  .text {
    border-radius: 16px;
    background: linear-gradient(
      0deg,
      rgba(92, 81, 186, 1) 35%,
      rgba(92, 81, 186, 1) 35%,
      rgba(255, 255, 255, 0.0018382352941176405) 100%
    );
    width: 100%;
    height: 200px;
    height: ${(props) => (props.secondary ? "150px" : null)};
    position: absolute;
    top: 60%;
    p {
      padding: 0 20px;
      font-size: 16px;
      font-family: "lato_bold";
      position: absolute;
      color: white;
      text-align: center;
      margin: 0 auto;
      top: 80%;
    }
    z-index: 3;
  }
  @media (min-width: 1200px) {
    iframe {
      width: 500px;
    }
  }
  @media (min-width: 1400px) {
    iframe {
      width: 600px;
    }
  }
`;
export default function VideoPurple(props) {
  const { titleVideo, videoUrl, ...rest } = props;
  return (
    <StyledVideo className="video_purple" {...rest}>
      <iframe
        title="myFrame"
        width="100%"
        height="480"
        src={videoUrl}
        frameBorder="0"
        scrolling="no"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen="false"
      />
      <div className="text">
        <p>{titleVideo}</p>
      </div>
    </StyledVideo>
  );
}
