import React from "react";
import Note from "../utils/Note";
import styled from "styled-components";
import { useState } from "react";
import Down from "../Assets/icon_dropdown.svg";
const StyledNotes = styled.div`
  background-color: var(--gray-ligth);
  padding: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 76px;
  h1 {
    font-family: "lato_bold";
    font-size: 24px;
    color: var(--strong-blue);
    text-align: center;
  }
  .header {
    padding: 50px 0;
    text-align: center;
  }
  .container_notes {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  img{
    margin-top: 44px;
    cursor: pointer;
  }
  .reverse{
    transform: rotate(180deg);
  }
  @media (min-width: 1200px) {
    .header {
      h1 {
        font-size: 40px;
      }
    }
    .container_notes {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
    }
  }
`;
export default function NotesWrapper() {
  const threeArray = notesArray.slice(0, 3);
  const [showArray, setShowArray] = useState(false);
  return (
    <StyledNotes>
      <div className="header">
        <h1>Notas</h1>
      </div>
      <div className="container_notes">
        {showArray === false ? (
          <>
            {threeArray.map((e, idx) => {
              return <Note key={idx} text={e.title} link={e.link} />;
            })}
           
          </>
        ) : (
          <>
            {notesArray.map((e, idx) => {
              return <Note key={idx} text={e.title} link={e.link} />;
            })}
          </>
        )}
      </div>
      {showArray === false ?  <img onClick={() => setShowArray(!showArray)} src={Down} alt="down_icon" /> :  <img className="reverse" onClick={() => setShowArray(!showArray)} src={Down} alt="down_icon" />}
     
    </StyledNotes>
  );
}

const notesArray = [
  {
    title: "¿Pérdida de audición? Fumar podría ser la causa",
    link: "https://www.reforma.com/aplicacioneslibre/preacceso/articulo/default.aspx?__rval=1&urlredirect=/perdida-de-audicion-fumar-podria-ser-la-causa/ar2420906",
  },
  {
    title:
      "Parálisis facial por síndrome de Ramsay Hunt podría causar sordera, ¿cómo recuperar la audición?",
    link: "https://www.salud180.com/salud-dia-a-dia/paralisis-facial-por-sindrome-de-ramsay-hunt-podria-causar-sordera-como-recuperar-la",
  },
  {
    title:
      "Por qué el uso de audífonos podría causar sordera, ¿se puede revertir el daño?",
    link: "https://www.salud180.com/salud-dia-a-dia/por-que-el-uso-de-audifonos-puede-causar-sordera-se-puede-revertir-el-dano",
  },
  {
    title: "¿Tienes un zubido en el oído? Te decimos qué es y qué debes hacer",
    link: "https://www.mujerde10.com/bienestar/salud/tienes-un-zumbido-en-el-oido-te-decimos-que-es-y-que-debes-hacer/2022/05/",
  },
  {
    title: "Hábitos saludables para cuidar tu audición",
    link: "https://plenilunia.com/portada/habitos-saludables-para-cuidar-tu-audicion/87504/",
  },
  {
    title: "10 Consejos para cuidar la salud auditiva de tus hijos",
    link: "https://www.melodijolola.com/super-mama/10-consejos-para-cuidar-la-salud-auditiva-de-tus-hijos",
  },
  {
    title: "Infecciones en el oído en niños: causas y tipos de otitis",
    link: "https://babycreysi.com/pequenos-traviesos/salud/infecciones-en-el-oido-en-ninos-causas-y-tipos-de-otitis/",
  },
  {
    title: "Contaminación acústica, ¡cuida tus oídos!",
    link: "https://wradio.com.mx/programa/2022/05/16/martha_debayle/1652714020_033777.html",
  },
  {
    title: "Contaminación acústica, cuida tus oídos",
    link: "https://www.marthadebayle.com/v3/radiov3/sosv3/contaminacion-acustica-cuida-tus-oidos/",
  },
  {
    title: "OTITIS, LO QUE TÚ Y TUS HIJOS DEBEN SABER",
    link: "https://rhpositivo.mx/opinion/item/7268-otitis-lo-que-tu-y-tus-hijos-deben-saber",
  },
  {
    title: "Acúfenos, problema de salud auditiva que aumenta en el mundo",
    link: "https://www.saludiario.com/acufenos-salud-auditiva/",
  },
  {
    title: "El uso de audífonos podría causar sordera",
    link: "https://www.imagenradio.com.mx/el-uso-de-audifonos-podria-causar-sordera",
  },
  {
    title:
      "¿Escuchas un zumbido persistente en los oídos, como tetera hirviendo?",
    link: "https://www.excelsior.com.mx/trending/escuchas-zumbido-persistente-oidos-como-tetera-hirviendo/1520584",
  },
  {
    title: "¿Sientes un zumbido persistente en los oídos?",
    link: "https://saludyvida.tips/sientes-un-zumbido-persistente-en-los-oidos/",
  },
  {
    title: "¿Qué tipo de deportes podrían afectar el oído de tus hijos?",
    link: "https://www.melodijolola.com/super-mama/que-tipo-de-deportes-podrian-afectar-el-oido-de-tus-hijos",
  },
  {
    title: "Zumbido en el oído: Experto nos explica sus causas y cómo tratarlo",
    link: "https://plenilunia.com/portada/zumbido-en-el-oido-experto-nos-explica-que-lo-causa-y-como-tratarlo/87821/",
  },
];
