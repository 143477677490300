import React, {useState, useEffect} from "react";
import Layout from "../Components/Layout/index";
import Portada from "../utils/Portada";
import Desktop from "../Assets/portada_medios_desktop.webp";
import Mobile from "../Assets/portada_medios_mobile.webp";
import VideosMedios from "../Components/VideosMedios";
import VideosFacebook from "../Components/VideosFacebook";
import NotesWrapper from "../Components/NotesWrapper";
const Medios = ()  => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted ? (
    <Layout>
      <Portada imageDesktop={Desktop} imageMobile={Mobile} title="IMON en Medios" />
      <VideosMedios />
      <VideosFacebook />
      <NotesWrapper />
    </Layout>
  ) : null
}
export default Medios 