import React from "react";
import styled from "styled-components";

const StyledVideo = styled.div`
  /* background-color: black; */
  position: relative;
  border-radius: 16px;
  margin: 20px;

  iframe {
    position: relative;
    border-radius: 16px;
    z-index: 2;
    border: 0.5px solid black;
  }
  .text {
    /* border-radius: 16px; */
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 1%,
      rgba(0, 0, 0, 1) 0%,
      rgba(66, 66, 66, 0) 51%
    );
    width: 100%;
    height: 100px;
    position: absolute;
    top: 70%;
    p {
      padding: 0 20px;
      font-size: 16px;
      font-family: "lato_bold";
      position: absolute;
      color: white;
      text-align: left;
      margin: 0 auto;
      top: 60%;
      text-decoration: underline;
      padding: 0px 90px 5px 30px;
    }
    z-index: 444;
  }
  @media (min-width: 1200px) {
    iframe {
      width: 400px !important;
      height: 500px !important;
    }
    .text {
      top: 90%;
      background-color: black;
      height: 60px;
      p{
        top: 27%;
      }
    }
  }
`;
export default function VideoTransparent(props) {
  const { titleVideo, videoUrl, ...rest } = props;
  return (
    <StyledVideo {...rest}>
      <iframe
        title="myFrame"
        src={videoUrl}
        frameBorder="0"
        scrolling="no"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen="false"
      />
      <div className="text">
        <p>{titleVideo}</p>
      </div>
    </StyledVideo>
  );
}
