import React from "react";
import styled from "styled-components";

const StyledNote = styled.div`
  width: 100%;
  max-width: 328px;
  .backTop {
    background-color: var(--demon-blue);
    height: 212px;
    border-radius: 16px 16px 0 0;
  }
  .backBottom {
    background-color: var(--white);
    min-height: 150px;
    height: 100%;
    padding: 20px;
    border-radius: 0 0 16px 16px;
    h5 {
      font-family: "lato_regular";
      text-align: left;
      font-size: 14px;
      color: var(--strong-blue);

      font-weight: 500;
    }
    p {
      text-decoration: underline;
      font-size: 14px;
      color: var(--purple);
      font-family: "lato_regular";
      font-weight: 500;
      text-align: right;
      margin-top: 5px;
    }
  }
  @media (min-width: 1200px) {
    max-width: 400px;
  }
  .backTop {
    background-color: var(--demon-blue);
    height: 250px;
  }
  .backBottom {
    height: 120px;

    h5 {
      font-size: 21px;
      height: 90px;
    }
    p {
      font-size: 21px;
      margin-top: 5px;
    }
  }
`;
export default function Note(props) {
  const { text, link } = props;
  return (
    <StyledNote>
      <div className="backTop"></div>

      <div className="backBottom">
          <h5>{text}</h5>
          <a href={link} target="_blank" rel="noreferrer">
            <p> Leer más</p>
          </a>
      </div>
    </StyledNote>
  );
}
