import React from "react";
import styled from "styled-components";
import VideoTransparent from "../utils/VideoTransparent";

const StyledVideos = styled.div`
  position: relative;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  .container_iframes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  iframe {
    height: 328px;
    width: 328px;
    border-radius: 16px;
  }
  h1 {
    font-family: "lato_bold";
    font-size: 24px;
    color: var(--strong-blue);
    text-align: center;
  }
  span {
    font-family: "lato_italic";
    font-style: italic;
    font-size: 24px;
    color: var(--strong-blue);
    text-align: center;
  }
  .header {
    padding: 50px 0;
    text-align: center;
  }
  @media (min-width: 1200px) {
    margin-bottom: 150px;
    .header {
      br {
        display: none;
      }
      h1 {
        font-size: 30px;
      }
      span {
        font-size: 30px;
        font-weight: 500;
      }
    }
  }
  @media (min-width: 1400px) {
    .container_iframes{
        flex-direction: row;
    }
  }
`;
export default function VideosFacebook() {
  return (
    <StyledVideos>
      <div className="header">
        <h1>
          Video cápsulas con <br />
          <span> Martha Debayle</span>
        </h1>
      </div>

      <div className="container_iframes">

        <VideoTransparent
          videoUrl={"https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FDebayleOficial%2Fvideos%2F3332654810301215%2F&show_text=false&width=380&t=0"}
          titleVideo={"¿Por qué hacerle un estudio de capacidad auditiva a tu bebé?"}
        />
         <VideoTransparent
          videoUrl={"https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FDebayleOficial%2Fvideos%2F376580271021545%2F&show_text=false&width=380&t=0"}
          titleVideo={"¿Qué es la otitis media en niños?"}
        />
         <VideoTransparent
          videoUrl={"https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FDebayleOficial%2Fvideos%2F698247894573375%2F&show_text=false&width=380&t=0"}
          titleVideo={"¿Cómo cuidar los oídos de tus hijos?"}
        />
      </div>
    </StyledVideos>
  );
}
