import React from "react";
import VideoPurple from "../utils/VideoPurple";
import styled from "styled-components";

const StyledVideos = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 90px auto;
  }
`;
export default function VideosMedios() {
  return (
    <StyledVideos>
      <VideoPurple
        videoUrl={"https://www.youtube.com/embed/ezuEVyUVOfc"}
        titleVideo={
          "Entrevista del Dr. Corvera con Diane Pérez en su programa 'Mi cita médica' en Televisa Californias, Canal 12"
        }
      />
      <VideoPurple
        videoUrl={"https://www.youtube.com/embed/rxvn9aw8KJQ"}
        titleVideo={
          "'¿Realmente perdemos la audición en la vejez?' Con Visiting Angels"
        }
      />
    </StyledVideos>
  );
}
